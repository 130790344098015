var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"mapping-bagtag"}},[_c('b-modal',{attrs:{"id":"modal-mapping-bagtag","size":"sm","centered":"","hide-footer":true},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v(" "+_vm._s(_vm.$t('golf_caddy_calendar_mapping_bagtag'))+" ")]),_c('ez-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ezGolf-icon-x","size":"24","color":"#114A9F"},on:{"click":close}})]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('b-card-body',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('golf_caddy_calendar_date')}},[_c('date-range-picker',{staticClass:"mr-1",attrs:{"ranges":false,"opens":"topleft","auto-apply":true,"single-date-picker":true,"locale-data":{
                  format: 'DD/MM/YYYY',
                  daysOfWeek: ['CN', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                  firstDay: 0
                }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('b-form-input',{ref:"caddyCode",attrs:{"placeholder":_vm.$t('golf_caddy_code'),"maxlength":"100"},model:{value:(_vm.caddyCode),callback:function ($$v) {_vm.caddyCode=$$v},expression:"caddyCode"}})],1),(_vm.caddyData)?_c('p',{staticClass:"mb-50"},[_c('strong',[_vm._v(_vm._s(_vm.caddyData.Name))])]):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('v-select',{attrs:{"options":_vm.listShift,"label":"label","reduce":function (label) { return label.id; },"clearable":false,"selectable":function (option) { return !option.active; }},model:{value:(_vm.caddyCalendarId),callback:function ($$v) {_vm.caddyCalendarId=$$v},expression:"caddyCalendarId"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('golf_boongking_dr_bagtag'),"maxlength":"15"},model:{value:(_vm.bagtagCode),callback:function ($$v) {_vm.bagtagCode=$$v},expression:"bagtagCode"}})],1)],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){return _vm.mappingBagtag_UCAC02()}}},[_vm._v(" "+_vm._s(_vm.$t('golf_common_save'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }