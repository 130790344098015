// import {
//   BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput, BRow, BCol,
//   BFormGroup, BButton, BCard, BDropdown, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay, BSidebar, VBToggle, BFormRadioGroup, BFormTimepicker
// } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import MappingBagtag from './MappingBagtag/MappingBagtag.vue'
import { booking } from '@/api/booking'
import ExcelJS from "exceljs";
import readXlsxFile from 'read-excel-file';
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ezToast } from '@core/utils/utils'

import flatPickr from 'vue-flatpickr-component'
import dayjs from 'dayjs'
import { caddyCalendar } from '@/api/caddy-calendar'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import { commonServices } from '@/api/common-services'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { getCssText } from '@/api/css-text'
import Cleave from 'vue-cleave-component'

export default {
  name: 'CaddyCalendar',
  components: {
    draggable,
    ToastificationContent,
    vSelect,
    flatPickr,
    'delete-dialog': DeleteDialog,
    Treeselect,
    DatePicker,
    MappingBagtag,
    Cleave,
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      userData: JSON.parse(localStorage.getItem('userData')),
      humanDate: null,
      caddyGroupSelected: null,
      listSex: [],
      listTypeOfContract: [],
      listCaddyPosition: [],
      listMaritalStatus: [],
      listCaddyGroup: [],
      listCaddyStatus: [
        {
          KeyValue: true,
          KeyName: 'Ngừng hoạt động',
        },
        {
          KeyValue: false,
          KeyName: 'Đang hoạt động',
        },
      ],
      listCaddy: [],
      listCourse: [],
      bloodList: ['O', 'A', 'B', 'AB'],
      listClotherSize: ['S', 'M', 'L', 'XL', 'XXL'],
      isAddNewCaddyGroup: false,
      addNewCaddyGroupName: null,
      isEditCaddyGroup: false,
      editCaddyGroupName: null,
      editCaddyGroupId: null,
      detailCaddy: {},
      searchObj: {
        keyword: null,
        age: null,
        courseId: null,
        sex: null,
      },
      preview: null, // MB
      search: {
        KeyWord: null,
        OpenDate: this.getUTC('start', 'day'),
        ExpirationDate: this.getUTC('start', 'day'),
      },
      data: {},
      detail: {
        StartDate: new Date(this.convertLocalDateToTimeHotel(new Date())),
        EndDate: new Date(this.convertLocalDateToTimeHotel(new Date())),
        Group: [],
        Caddy: [],
        StartTime: `${new Date().getHours()}:${new Date().getMinutes()}`,
        EndTime: new Date().getHours() >= 24 ? 0 : `${new Date().getHours() + 1}:${new Date().getMinutes()}`,
      },
      updateStatusCaddy: {
        Caddy: [],
        StartTime: `${new Date().getHours()}:${new Date().getMinutes()}` ,
        StartDate: this.getUTC('start', 'day'),
        EndTime: new Date().getHours() >= 24 ? 0 : `${new Date().getHours() + 1}:${new Date().getMinutes()}`,
        EndDate: this.getUTC('start', 'day'),
        WorkingStatus: null,
      },
      selectCaddy: [],
      selectUpdateCaddy: [],
      listCaddyTree: [],
      checkDateDetail: false,
      isEdit: false,
      loading: true,
      slideBar_caddy: false,
      statusFilter: 'all',
      today: new Date(),
      dateRange: [new Date(dayjs().format('YYYY-MM-DD')),
        new Date(dayjs().add(1, 'day').format('YYYY-MM-DD')),
      ],
      listCaddyTree: [],
      inputMaskTime: {
        time: true,
        timePattern: ['h', 'm'],
      },
      isLoadingRCA03: false,
      slideBar_update_caddy: false,
      listWorkingStatusCaddy: [],
      jsonPropPrint: {
        CaddyCalendar: []
      },
      fileName: null,
      pLang: null,
      pToken: null,
      pTimeZone: null,
      pTimeZoneOffset: null,
      pUrlReport: null,
      isSkipCaddyInShift: false
    }
  },
  watch: {
    // dateRange(val) {
    //   this.search.OpenDate = val[0]
    //   this.search.ExpirationDate = dayjs(val[1]).add(1, 'day').add(-1, 'minutes')
    // },
  },
  created() {
    this.getParam()
    this.api_RCOM01()
    this.getListCaddyCalendar_RCAC01_AC1()
    this.callApi_RCA05()
    this.getGroupCaddy()
    this.getListCaddyStatus_RCWS01()
  },
  computed: {
    previewImage() {
      return this.preview
    },
  },
  methods: {
    _dayjs: dayjs,
    async getListCaddyCalendar_RCAC01_AC1() {
      this.jsonPropPrint.CaddyCalendar = []
      this.loading = true
      // Lấy danh sách tổ caddy
      await caddyCalendar
        .getListCaddyCalendar(this.search)
        .then(res => {
          if (res) {
            this.data = { ...res.Data }
            this.loading = false
            res.Data.CaddyCalendar.forEach(x => {
              x.Shift.forEach(y => {
                y.Caddy.forEach(z => {
                  this.jsonPropPrint.CaddyCalendar.push({
                    Caddy: z,
                    StartTime: y.StartTime,
                    EndTime: y.EndTime
                  })
                })
              })
            })
          }
        })
    },
    getGroupCaddy() {
      commonServices
        .getCommon({ KeyGroup: 'CADDY_PO' })
        .then(res => {
          if (res) {
            this.listCaddyPosition = res.Data.filter(x => x.LangId == this.currentLang)
          }
        })
    },
    async callApi_RCA05() {
      this.isLoadingRCA03 = true
      const body = {
        KeyWord: '',
        Age: null,
        SexCode: null,
        CourseId: [],
      }
      await caddyCalendar
        .api_RCA05(body)

        .then(res => {
          if (res) {
            this.listCaddy = res.Data.Caddy.map(x => Object.assign(x, {
              CaddyGroupId: x.CaddyGroup.Id,
              CaddyGroupName: x.CaddyGroup.CaddyGroupName,
            }))
            const arrGroup = res.Data.Caddy.map(x => ({
              id: `TO_${x.CaddyGroupId}`,
              label: x.CaddyGroupName,
              children: [],
            }))
            this.listCaddyTree = [...Array.from(new Set(arrGroup.map(JSON.stringify))).map(JSON.parse)]
            this.listCaddyTree.forEach(element => {
              this.listCaddy.forEach(caddy => {
                if (element.id === `TO_${caddy.CaddyGroupId}`) {
                  element.children.push({
                    id: caddy.Id,
                    label: `${caddy.CaddyName} (#${caddy.CaddyCode})`,
                    CaddyCode: caddy.CaddyCode
                  })
                }
              })
            })
          }
        })
      // setTimeout(() => {
      this.isLoadingRCA03 = false
      // }, 3000)
    },
    async detailCaddyCalendar(date, item) {
      this.isEdit = true
      const body = {
        CaddyCalendar: {
          Id: item.Id,
        },
      }
      await caddyCalendar
        .api_RCAC02(body)
        .then(res => {
          if (res.Status === '200') {
            this.detail = JSON.parse(JSON.stringify(res.Data))
            this.detail.CaddyCalendar.WorkingDay = this.convertLocalDateToTimeHotel(res.Data.CaddyCalendar.WorkingDay, 'YYYY-MM-DD')
            // this.detail.CaddyCalendar.EndDate = this.convertLocalDateToTimeHotel(res.Data.CaddyCalendar.EndDate, 'YYYY-MM-DD')
            this.detail.CaddyCalendar.StartTime = this.utcConvertToLocalTimeZone(res.Data.CaddyCalendar.StartTime, 'HH:mm')
            this.detail.CaddyCalendar.EndTime = this.utcConvertToLocalTimeZone(res.Data.CaddyCalendar.EndTime, 'HH:mm')
            this.checkDate(`${res.Data.CaddyCalendar.WorkingDay}Z`, this.detail.CaddyCalendar.EndTime)

            this.selectCaddy = [] // []

            this.listCaddyTree.forEach(element => {
              if (element.children.length === res.Data.CaddyCalendar.Caddy.filter(x => `TO_${x.CaddyGroupId}` == element.id).length) {
                this.selectCaddy.push(element.id)
              } else {
                res.Data.CaddyCalendar.Caddy.filter(x => `TO_${x.CaddyGroupId}` == element.id).forEach(y => {
                  this.selectCaddy.push(y.Id)
                })
              }
            })

            this.slideBar_caddy = true
          }
        })
    },
    checkDate(date, time) {
      const dateTime = new Date(date)
      dateTime.setHours(time.split(':')[0])
      dateTime.setMinutes(time.split(':')[1])
      dateTime.setSeconds('00')
      const d = new Date()
      const day = d.toLocaleString(
        'en-US',
        { timeZone: localStorage.getItem('timeZone') },
      )
      // var d1 = new Date(date);
      const day1 = dateTime.toLocaleString(
        'en-US',
        { timeZone: localStorage.getItem('timeZone') },
      )
      this.checkDateDetail = (new Date(dayjs(day1)) < new Date(dayjs(day)))
    },
    isDisabledTr(date) {
      const dateTime = new Date(`${date}Z`)
      dateTime.setHours('23')
      dateTime.setMinutes('59')
      dateTime.setSeconds('00')
      const d = new Date()
      const day = d.toLocaleString(
        'en-US',
        { timeZone: localStorage.getItem('timeZone') },
      )
      // var d1 = new Date(date);
      const day1 = dateTime.toLocaleString(
        'en-US',
        { timeZone: localStorage.getItem('timeZone') },
      )
      return (new Date(dayjs(day1)) < new Date(dayjs(day)))
      // return this.getUTC('start')
    },
    isDisabledTd(time) {
      const dateTime = new Date(time)
      const d = new Date()
      const day = d.toLocaleString(
        'en-US',
        { timeZone: localStorage.getItem('timeZone') },
      )
      // var d1 = new Date(date);
      const day1 = dateTime.toLocaleString(
        'en-US',
        { timeZone: localStorage.getItem('timeZone') },
      )
      return (new Date(dayjs(day1)) < new Date(dayjs(day)))
    },
    addCaddyByDetail() {
      if (this.selectCaddy.length > 0) {
        const arrSelectCaddyId = JSON.parse(JSON.stringify(this.selectCaddy))
        const arrselectCaddy = []
        arrSelectCaddyId.forEach(element => {
          const name = `${element}_`
          const myArray = name.split('_')
          if (myArray[0] === 'TO') {
            this.listCaddy.forEach(caddy => {
              if (caddy.CaddyGroupId == myArray[1]) {
                arrselectCaddy.push(caddy)
              }
            })
          } else {
            this.listCaddy.forEach(caddy => {
              if (caddy.Id == element) {
                arrselectCaddy.push(caddy)
              }
            })
          }
        })

        this.detail.CaddyCalendar.Caddy = arrselectCaddy
        // console.log(arrselectCaddy)
        // arrselectCaddy.forEach(e => {
        //   if (this.detail.CaddyCalendar.Group.length > 0) {
        //     if (this.detail.CaddyCalendar.Group.filter(g => g.Id === e.CaddyGroupId).length > 0) {
        //       this.detail.CaddyCalendar.Group.forEach(group => {
        //         if (e.CaddyGroupId === group.Id) {
        //           if (group.Caddy.filter(c => c.Id === e.Id).length === 0) {
        //             group.Caddy.push(e)
        //           }
        //         }
        //       })
        //     } else {
        //       this.detail.CaddyCalendar.Group.push({
        //         CaddyGroupName: e.CaddyGroupName,
        //         Id: e.CaddyGroupId,
        //         Caddy: [e],
        //       })
        //     }
        //   } else {
        //     this.detail.CaddyCalendar.Group.push({
        //       CaddyGroupName: e.CaddyGroupName,
        //       Id: e.CaddyGroupId,
        //       Caddy: [e],
        //     })
        //   }
        // })
      }
      // this.selectCaddy = []
    },
    addCaddyByUpdateStatus() {
      console.log(this.selectUpdateCaddy)
      if (this.selectUpdateCaddy.length > 0) {
        const arrSelectCaddyId = JSON.parse(JSON.stringify(this.selectUpdateCaddy))
        const arrselectCaddy = []
        arrSelectCaddyId.forEach(element => {
          const name = `${element}_`
          const myArray = name.split('_')
          if (myArray[0] === 'TO') {
            this.listCaddy.forEach(caddy => {
              if (caddy.CaddyGroupId == myArray[1]) {
                arrselectCaddy.push(caddy)
              }
            })
          } else {
            this.listCaddy.forEach(caddy => {
              if (caddy.Id == element) {
                arrselectCaddy.push(caddy)
              }
            })
          }
        })

        this.updateStatusCaddy.Caddy = arrselectCaddy
        // console.log(arrselectCaddy)
        // arrselectCaddy.forEach(e => {
        //   if (this.detail.CaddyCalendar.Group.length > 0) {
        //     if (this.detail.CaddyCalendar.Group.filter(g => g.Id === e.CaddyGroupId).length > 0) {
        //       this.detail.CaddyCalendar.Group.forEach(group => {
        //         if (e.CaddyGroupId === group.Id) {
        //           if (group.Caddy.filter(c => c.Id === e.Id).length === 0) {
        //             group.Caddy.push(e)
        //           }
        //         }
        //       })
        //     } else {
        //       this.detail.CaddyCalendar.Group.push({
        //         CaddyGroupName: e.CaddyGroupName,
        //         Id: e.CaddyGroupId,
        //         Caddy: [e],
        //       })
        //     }
        //   } else {
        //     this.detail.CaddyCalendar.Group.push({
        //       CaddyGroupName: e.CaddyGroupName,
        //       Id: e.CaddyGroupId,
        //       Caddy: [e],
        //     })
        //   }
        // })
      }
      // this.selectCaddy = []
    },
    openPopupAdd() {
      this.isEdit = false
      this.checkDateDetail = false
      this.selectCaddy = []
      this.isSkipCaddyInShift = false
      const today = new Date()
      const ob = {
        StartDate: new Date(this.convertLocalDateToTimeHotel(new Date())),
        EndDate: new Date(this.convertLocalDateToTimeHotel(new Date())),
        Group: [],
        Caddy: [],
        StartTime: `${new Date().getHours()}:${new Date().getMinutes()}`,
        EndTime: new Date().getHours() >= 24 ? 0 : `${new Date().getHours() + 1}:${new Date().getMinutes()}`,
      }
      this.detail = {
        CaddyCalendar: { ...ob },
      }
      this.slideBar_caddy = true
    },
    ICAC01_AC5() {
      const arr = []
      this.detail.CaddyCalendar.Caddy.forEach(x => {
        arr.push(x.Id)
      })

      if (arr.length === 0) return
      const startTime = new Date(this.detail.CaddyCalendar.StartDate)
      startTime.setHours(this.detail.CaddyCalendar.StartTime.split(':')[0])
      startTime.setMinutes(this.detail.CaddyCalendar.StartTime.split(':')[1])
      startTime.setSeconds('00')

      const endTime = new Date(this.detail.CaddyCalendar.StartDate)
      endTime.setHours(this.detail.CaddyCalendar.EndTime.split(':')[0])
      endTime.setMinutes(this.detail.CaddyCalendar.EndTime.split(':')[1])
      endTime.setSeconds('00')

      const endDate = new Date(this.detail.CaddyCalendar.EndDate)
      const body = {
        CaddyCalendar: {
          StartDate: startTime.toISOString(),
          EndDate: endDate.toISOString(),
          StartTime: startTime.toISOString(),
          EndTime: endTime.toISOString(),
          CaddyID: arr, // Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse),
        },
        IsCheckDuplicate: !this.isSkipCaddyInShift
      }
      caddyCalendar
        .api_ICAC01(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.slideBar_caddy = false
            this.getListCaddyCalendar_RCAC01_AC1()
          } else {
            this.showResToast(res)
          }
        })
    },
    async updateCaddy_UCAC01() {
      const WorkingDay = new Date(this.detail.CaddyCalendar.WorkingDay)
      WorkingDay.setHours(this.detail.CaddyCalendar.StartTime.split(':')[0])
      WorkingDay.setMinutes(this.detail.CaddyCalendar.StartTime.split(':')[1])
      WorkingDay.setSeconds('00')

      const endTime = new Date(this.detail.CaddyCalendar.WorkingDay)
      endTime.setHours(this.detail.CaddyCalendar.EndTime.split(':')[0])
      endTime.setMinutes(this.detail.CaddyCalendar.EndTime.split(':')[1])
      endTime.setSeconds('00')

      const arr = []
      this.detail.CaddyCalendar.Caddy.forEach(x => {
        arr.push(x.Id)
      })
      const body = {
        CaddyCalendar: {
          Id: this.detail.CaddyCalendar.Id,
          WorkingDay: WorkingDay.toISOString(),
          StartTime: WorkingDay.toISOString(),
          EndTime: endTime.toISOString(),
          CaddyID: arr, // Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse),
        },
      }

      // console.log(body)
      await caddyCalendar
        .updateCaddyCalendar(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.slideBar_caddy = false
            this.getListCaddyCalendar_RCAC01_AC1()
          }
        })
    },
    call_DCAC01_AC3(data) {
      const boby = {
        CaddyCalendar: {
          Id: data.Id,
        },
      }
      caddyCalendar
        .api_DCAC01(boby)
        .then(res => {
          if (res) {
            this.slideBar_caddy = false
            this.getListCaddyCalendar_RCAC01_AC1()
          }
        })
    },
    removeCaddy(index,isDetail) {
      // arr.splice(index, 1)
      // if (arr.length === 0) {
        if(isDetail) {
          this.detail.CaddyCalendar.Caddy.splice(index, 1)
        } else {
          this.updateStatusCaddy.Caddy.splice(index, 1)
        }
      // }
    },
    openModalMappingBagtag() {
      this.$root.$emit('bv::show::modal', 'modal-mapping-bagtag')
    },
    removeCaddyGroup(index) {
      const tmp = this.detail.CaddyCalendar.Group[index].Caddy.filter(x => x.WorkingStatus != 'A')
      this.detail.CaddyCalendar.Group[index].Caddy = [...tmp]
    },
    // printCaddyCalendar() {
    //   const prtHtml = document.getElementById('caddyCalendar-print').innerHTML
    //   let stylesHtml = `<style type="text/css">.ez-icon {display: none} .no-print {display: none} ${getCssText()}</style>`
    //   for (const node of [...document.querySelectorAll('link')]) {
    //     stylesHtml += node.outerHTML
    //   }

    //   // Open the print window
    //   const WinPrint = window.open('', '', 'left=0,top=0,height=900,toolbar=0,scrollbars=0,status=0')

    //   WinPrint.document.write(`<!DOCTYPE html>
    //   <html>
    //     <head>
    //       ${stylesHtml}
    //     </head>
    //     <body>
    //       <div style="text-align: center; margin: 10px 0">
    //         <h2>Lịch làm việc caddy</h2>
    //         <i>Thời gian in: ${this.utcConvertToLocalTimeZone(new Date(), 'DD/MM/YYYY HH:mm:ss')}</i>
    //       </div>
    //       ${prtHtml}
    //       <script> window.onload = function (e){ window.print(); }</script>
    //     </body>
    //   </html>`)

    //   WinPrint.document.close()
    //   // WinPrint.focus();
    //   // WinPrint.print();
    // },
    printCaddyCalendar(ischeck, data) {
      var jsonTemp = {
        CaddyCalendar: []
      }
      if(ischeck) {
        data.Caddy.forEach(x => {
          jsonTemp.CaddyCalendar.push(
            {
              Caddy: x,
              StartTime: data.StartTime,
              EndTime: data.EndTime
            }
          )
        })
        const body = {
          ReportName: this.fileName,
          Params: {
            StartDate: data.StartTime,
            EndDate: data.EndTime,
            pLang: this.pLang,
            pToken: this.pToken,
            pUrlReport: this.pUrlReport,
            pTimeZone: this.pTimeZone,
            pTimeZoneOffset: this.pTimeZoneOffset,
            json: JSON.stringify(jsonTemp)
          },
        }
        this.getInoicePdf(body)
      } else {
        if(this.dayjs(this.search.ExpirationDate) - this.dayjs(this.search.OpenDate) != 0) {
          this.showToast('error', this.$t("golf_caddy_calendar_allow_print_one_day"))
          return
        }
        const body = {
          ReportName: this.fileName,
          Params: {
            StartDate: this.search.OpenDate,
            EndDate: this.search.ExpirationDate,
            pLang: this.pLang,
            pToken: this.pToken,
            pUrlReport: this.pUrlReport,
            pTimeZone: this.pTimeZone,
            pTimeZoneOffset: this.pTimeZoneOffset,
            json: JSON.stringify(this.jsonPropPrint)
          },
        }
        this.getInoicePdf(body)
      }
      
    },
    async api_RCOM01() {
      const body = {
        KeyGroup: 'REPORT_CONFIG',
      }
      await commonServices.getCommon(body).then(response => {
        this.fileName = response.Data.find(x => x.KeyCode == 'CADDY_CALENDAR_REPORT_FILE_NAME').KeyValue
      })
    },
    async api_RCF00() {
      await commonServices.api_RCF00().then(response => {
        this.pUrlReport = `${response.Data.find(x => x.SettingCode === 'SITE_API_URL').SettingValue}/rp`
      })
    },
    getParam() {
      const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
      this.pLang = localStorage.getItem('systemLanguage') || '1000000'
      this.pToken = (userGolfData) ? userGolfData.AccessToken : ''
      this.pTimeZone = localStorage.getItem('timeZone')
      this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset')
      this.api_RCF00()
    },
    async getInoicePdf(body) {
      
      await booking.api_RRPDF(body).then(response => {
        const decodedData = Buffer.from(response.Data.FileContents, 'base64')
        const newBlob = new Blob([decodedData], {
          type: 'application/pdf',
        })
        const iframe = document.createElement('iframe')
        const url = window.URL.createObjectURL(newBlob)
        iframe.src = url
        iframe.style.display = 'none'
        iframe.onload = () => {
          iframe.contentWindow.focus()
          iframe.contentWindow.print()
        }
        document.body.appendChild(iframe)
      })
    },
    HandleUpdateStatusCaddy() {
      this.resetDataUpdateStatusCaddy()
      this.slideBar_update_caddy = true
    },
    async getListCaddyStatus_RCWS01() {
      const response =  await caddyCalendar.api_RCWS01()
      this.listWorkingStatusCaddy = response.Data.CaddyWorkingStatus
      this.updateStatusCaddy.WorkingStatus = this.listWorkingStatusCaddy[0].WorkingStatus
    },
    async updateStatusCaddy_UCAC04() {
      if(this.updateStatusCaddy.StartTime && this.updateStatusCaddy.EndTime && this.updateStatusCaddy.Caddy.length == 0) {
        this.showToast('error', 'Vui lòng điền đầy đủ thông tin')
        return
      }
      const StartTimeTemp = this.dayjs(this.updateStatusCaddy.StartDate).utc(localStorage.getItem('timeZone'))
      .set('hour', this.updateStatusCaddy.StartTime.split(':')[0])
      .set('minute', this.updateStatusCaddy.StartTime.split(':')[1])
      .set('second', 0)
      .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
      .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      const EndTimeTemp = this.dayjs(this.updateStatusCaddy.StartDate).utc(localStorage.getItem('timeZone'))
      .set('hour', this.updateStatusCaddy.EndTime.split(':')[0])
      .set('minute', this.updateStatusCaddy.EndTime.split(':')[1])
      .set('second', 0)
      .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
      .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      const body = {
        CaddyId: this.updateStatusCaddy.Caddy.map(x => x.Id),
        CaddyCode: [],
        StartTime: StartTimeTemp,
        StartDate:this.updateStatusCaddy.StartDate,
        EndTime: EndTimeTemp,
        EndDate:this.updateStatusCaddy.EndDate,
        WorkingStatus: this.updateStatusCaddy.WorkingStatus
      }
      const response =  await caddyCalendar.api_UCAC04(body)
      this.showResToast(response)
      if(response.Status === '200') {
        this.slideBar_update_caddy = false
        this.getListCaddyCalendar_RCAC01_AC1()
      }
    },
    resetDataUpdateStatusCaddy() {
      this.updateStatusCaddy = {
        Caddy: [],
        StartTime: `${new Date().getHours()}:${new Date().getMinutes()}` ,
        StartDate: this.getUTC('start', 'day'),
        EndTime: new Date().getHours() >= 24 ? 0 : `${new Date().getHours() + 1}:${new Date().getMinutes()}`,
        EndDate: this.getUTC('start', 'day'),
        WorkingStatus: this.listWorkingStatusCaddy[0].WorkingStatus,
      }
    },
    triggerClickChooFiles() {
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.click();
    },
    triggerClickChooFilesAddCaddy() {
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile1');
      fromChooseFile.click();
    },
    async readFileExcel() {
      await readXlsxFile(this.$refs.choosefileExcel.files[0]).then(async (rows) => {
        const dataImport = []
        const data = []
        rows.forEach((x, index) => {
          if(index != 0 && x[0]) {
            dataImport.push(String(x[0]))
          }
        })
        this.listCaddyTree.forEach(x => {
          x.children.forEach(y => {
            if(dataImport.find(caddy => caddy.toUpperCase() == y.CaddyCode.toUpperCase())) {
              data.push(y.id)
            }
          })
        })
        if(data.length > 0) {
          this.selectUpdateCaddy = data
          this.addCaddyByUpdateStatus()
        }
      });
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.value = ""
    },
    async readFileExcelAddCaddy() {
      await readXlsxFile(this.$refs.choosefileExcel.files[0]).then(async (rows) => {
        const dataImport = []
        const data = []
        rows.forEach((x, index) => {
          if(index != 0 && x[0]) {
            dataImport.push(String(x[0]))
          }
        })
        this.listCaddyTree.forEach(x => {
          x.children.forEach(y => {
            console.log(y.CaddyCode.toUpperCase())
            console.log(dataImport)
            if(dataImport.find(caddy => caddy.toUpperCase() == y.CaddyCode.toUpperCase())) {
              data.push(y.id)
            }
          })
        })
        if(data.length > 0) {
          this.selectCaddy = data
          this.addCaddyByDetail()
        }
      });
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile1');
      fromChooseFile.value = ""
    },
    generateXLSX() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Format Member");

      worksheet.columns = [
          { header: "Mã caddy", key: "CaddyCode"},
          { header: "Mã nhân viên", key: "EmployeeCode"},
      ];
      this.updateStatusCaddy.Caddy.forEach(x => {
        worksheet.addRow(x)
      })
      workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `TemplateCaddyExport.xlsx`)
      })
    },
    generateXLSXAddCaddy() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Format Member");

      worksheet.columns = [
          { header: "Mã caddy", key: "CaddyCode"},
          { header: "Mã nhân viên", key: "EmployeeCode"},
      ];
      this.detail.CaddyCalendar.Caddy.forEach(x => {
        worksheet.addRow(x)
      })
      workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `TemplateCaddyExport.xlsx`)
      })
    },
    async arrangeCaddyCalender(data) {
      const body = {
        CaddyCalendarId: data.Id
      }
      await caddyCalendar.api_UCAC05(body).then(res => {
        this.showResToast(res)
        if(res,Status == 200) {
          this.getListCaddyCalendar_RCAC01_AC1()
        }
      })
    }
  },
}
