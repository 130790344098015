import vSelect from 'vue-select'
import { caddyCalendar } from '@/api/caddy-calendar'

export default {
  name: 'MappingBagtag',
  components: {
    vSelect,
  },
  data() {
    return {
      caddyCode: null,
      date: this.getUTC('start', 'day'),
      caddyCalendarId: null,
      bagtagCode: null,
      listShift: [],
      caddyData: null,
      isLoading: false,
      isOpen: false,
    }
  },
  watch: {
    caddyCode(val) {
      if (val) {
        this.searchCaddyCalendarByCaddyCode_RCAC03(val)
      } else {
        this.listShift = []
        this.caddyCalendarId = null
        this.bagtagCode = null
        this.caddyData = null
      }
    },
    isOpen(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.caddyCode.$el.focus()
        }, 100)
      } else {
        // reset lại list bdc khi đóng sidebar
        this.caddyCode = null
      }
    },
  },
  created() {

  },
  methods: {
    async searchCaddyCalendarByCaddyCode_RCAC03(caddyCode) {
      const body = {
        Date: this.date,
        CaddyCode: caddyCode,
      }
      await caddyCalendar
        .api_RCAC03(body)
        .then(res => {
          this.listShift = res.Data.CaddyCalendar.map(x => ({
            id: x.Id,
            label: `${this.convertUTC(x.StartTime, 'HH:mm')} - ${this.convertUTC(x.EndTime, 'HH:mm')}`,
            active: x.Active,
          }))
          this.caddyData = (res.Data.Caddy) ? res.Data.Caddy : null
          this.caddyCalendarId = (this.listShift[0]) ? this.listShift[0].id : null
          this.bagtagCode = (this.listShift[0]) ? res.Data.CaddyCalendar.find(x => x.Id == this.caddyCalendarId).BagtagCode : null
        })
    },
    async mappingBagtag_UCAC02() {
      this.isLoading = true
      const body = {
        CaddyCalendarId: this.caddyCalendarId,
        CaddyId: this.caddyData.Id,
        BagtagCode: this.bagtagCode,
      }
      await caddyCalendar
        .api_UCAC02(body)
        .then(res => {
          this.showResToast(res)
        })
      this.isLoading = false
    },
  },
}
